import { computed, InjectionKey, provide, ref, ShallowRef, shallowRef, watch } from 'vue';
import { MaybeRefOrGetter, toValue } from '@vueuse/core';

import { useMutation } from '@/helpers/composables/useApollo';
import { useLanguageStore } from '@/helpers/composables/useStores';

import { NewPageType, SponsoredRecommendationsInput, SraPlacement, SraPlacementContext } from '@/@types/graphql-types';
import { ObjectType, SraFormat } from '@/@types/graphql-types';
import { Platform } from '@/@types/graphql-types';

import { TrackingAppId, ApplicationContext } from '@/helpers/tracking/tracking-helper';
import { getSrDebuggerInfo } from '@/helpers/sponsoredrec-debugging-tools';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import {
	SendSraBidDocument,
	SendSraBidMutation,
	SendSraBidMutationVariables,
} from '@/pages/graphql/mutations/SendSraBid.mutation';
import { SingleTitleSR } from './types';

export type SRCatalogueKey = `${SraPlacement}-${SRPageTypes}`;
export type SRAdType = 'POSTER' | 'CINEMA' | 'LIST' | 'VIDEO';
export type SRPageTypes =
	| 'VIEW_TITLE_DETAIL'
	| 'VIEW_POPULAR'
	| 'VIEW_HOME_USER'
	| 'VIEW_SEARCH_SUGGESTER'
	| 'VIEW_SEARCH'
	| NewPageType;

interface UseSponsoredRecOptions {
	pageType: SRPageTypes;
	placement: SraPlacement;

	testingMode?: boolean;
	alwaysReturnBidID?: boolean;
	jwEntityID?: MaybeRefOrGetter<SraPlacementContext['jwEntityID'] | undefined>;
	makeFreshBid?: boolean;
	isEnabled?: MaybeRefOrGetter<boolean>;
}

export const SR_PROVIDER_KEY = Symbol() as InjectionKey<SRCatalogueKey>;

const supportedFormats = [SraFormat.Image, SraFormat.Video];
const supportedObjectTypes = [ObjectType.Movie, ObjectType.Show, ObjectType.GenericTitleList, ObjectType.ShowSeason];

/** Record of every campaign based upon it's placement-pageType */
const sponsoredAdsCatalogue = shallowRef<Record<SRCatalogueKey, ShallowRef<SponsoredAdFragment | undefined>>>(
	{} as Record<SRCatalogueKey, ShallowRef<SponsoredAdFragment | undefined>>
);

/** Standalone logic to get a Sponsored Recommendation. */
export function useSendBidId({
	pageType,
	placement,
	testingMode = false,
	alwaysReturnBidID = true,
	jwEntityID = undefined,
	isEnabled = true,
}: UseSponsoredRecOptions) {
	const { language, country } = useLanguageStore();
	const isSRLoaded = ref(false);

	// Set the key
	const srCatalogueKey: SRCatalogueKey = `${placement}-${pageType}`;
	provide(SR_PROVIDER_KEY, srCatalogueKey);

	sponsoredAdsCatalogue.value[srCatalogueKey] = shallowRef(undefined);

	// Get the debugging information to override campaign settings
	const srDebuggerInfo = getSrDebuggerInfo();

	// Create Mutation Inputs, with debugging information
	const sraInput = computed<SponsoredRecommendationsInput>(() => ({
		// Campaign specific information
		pageType: toValue(pageType),
		placement: toValue(placement),

		// Default settings
		language: language.value,
		country: country.value,
		applicationContext: ApplicationContext, // now with appContext
		appId: TrackingAppId,
		platform: Platform.Web,

		// Have every SR Placement supoort every format
		supportedFormats,
		supportedObjectTypes,
		alwaysReturnBidID,

		// Debugging information
		geoCountry: srDebuggerInfo?.geoCountry,
		testingModeCampaignName: srDebuggerInfo?.campaignName,
		testingModeSubcampaignId: srDebuggerInfo?.subcampaignId,
		testingModeForceHoldoutGroup: !!srDebuggerInfo?.holdoutGroup,
		testingMode: srDebuggerInfo?.testingMode || testingMode,
	}));

	// Manage Mutation Request
	const { onDone, onError, mutate } = useMutation<SendSraBidMutation, SendSraBidMutationVariables>(
		SendSraBidDocument,
		() => ({
			variables: {
				language: language.value,
				country: country.value,
				input: {
					sraInput: sraInput.value,
					placementContext: { jwEntityID: toValue(jwEntityID) ?? undefined },
				},
			},
		})
	);

	onDone(({ data }) => {
		if (srCatalogueKey != null && data?.sendSraBid.ad != null) {
			sponsoredAdsCatalogue.value[srCatalogueKey].value = data.sendSraBid.ad;

			// Ensure SR mutation is completed
			isSRLoaded.value = true;
		}
	});

	onError(error =>
		captureMessageForSentry(
			'[GraphQL Send Bid Request]:',
			{ error, where: 'Composable: useSendBidId', sraInput: sraInput.value },
			'error',
			{ SR: srCatalogueKey }
		)
	);

	watch(
		[() => toValue(jwEntityID), () => toValue(isEnabled)],
		([newEntity, newIsEnabled]) => (newIsEnabled || newEntity) && mutate(),
		{ immediate: true }
	);

	const sponsoredAd = computed(() => getSrPlacement(srCatalogueKey));

	const isShadowCampaign = computed(() => !!sponsoredAd.value?.bidId && sponsoredAd.value?.campaign === null);

	const sponsoredAdType = computed(() => sponsoredAd.value?.campaign?.creativeType ?? null);

	const hasActiveSRCampaign = computed(
		() => sponsoredAd.value?.holdoutGroup === false && sponsoredAd.value?.campaign !== null
	);

	return {
		isSRLoaded,
		sponsoredAd,
		isShadowCampaign,
		sponsoredAdType,
		hasActiveSRCampaign,
	};
}

interface SaveSRPlacementTypes {
	pageType: MaybeRefOrGetter<SRPageTypes>;
	placement: MaybeRefOrGetter<SraPlacement>;
	ad: MaybeRefOrGetter<SponsoredAdFragment>;
}

/** Save the SR to the global SR catalogue. This is a shim until all SR requests are standalone. */
export function saveBidIdPlacement({ placement, pageType, ad }: SaveSRPlacementTypes) {
	const srCatalogueKey: SRCatalogueKey = `${toValue(placement)}-${toValue(pageType)}`;

	if (srCatalogueKey == undefined || toValue(ad) == undefined) return;

	sponsoredAdsCatalogue.value[srCatalogueKey] = shallowRef(toValue(ad));
}

/**
 * Return the SR campaign based upon the placement of the campaign
 *
 * @param srCatalogueKey Placement of the campaign
 */
export function getSrPlacement(srCatalogueKey: SRCatalogueKey) {
	return sponsoredAdsCatalogue.value[srCatalogueKey]?.value ?? null;
}

/**
 * Get the SR campaign and it's placement based upon the Bid ID
 *
 * @param bidId {string} The unique bid ID for that instance of the SR campaign
 */
export function getSrFromBidId(bidId: string | undefined) {
	const [srCatalogueKey, sponsoredAd] = Object.entries(sponsoredAdsCatalogue.value).find(
		([, ad]) => bidId === ad.value?.bidId
	) ?? [null, null];

	return {
		sponsoredAd: toValue(sponsoredAd) ?? null,
		placement: (srCatalogueKey?.split('-')?.[0] as SraPlacement | undefined) ?? null,
	};
}

export function useCampaignInfo(campaign?: MaybeRefOrGetter<SponsoredAdFragment['campaign'] | undefined>) {
	const campaignNode = computed<SingleTitleSR>(() => toValue(campaign)?.node as SingleTitleSR);

	const promotionalTitle = computed(() => {
		if (toValue(campaign)?.promotionalTitle) {
			return toValue(campaign)?.promotionalTitle;
		}

		const campaignTitle = campaignNode.value.content.title;

		if (campaignNode.value?.__typename === 'Season') {
			return `${campaignNode.value.show.content.originalTitle} - ${campaignTitle}`;
		}

		return campaignTitle;
	});

	const genresList = computed(() => {
		return campaignNode.value.content.genres.map(genre => genre.translation);
	});

	const releaseYear = computed(() => campaignNode.value.content.originalReleaseYear?.toString());

	const promotionalText = computed<string | null | undefined>(() => toValue(campaign)?.promotionalText);

	const promotionalTextWithGenreFallback = computed<string>(
		() => promotionalText.value || [releaseYear.value, ...genresList.value].join(' - ')
	);

	// HACK: APPLE PLUS - display disclaimer text
	const hasDisclaimerText = computed(
		() => toValue(campaign)?.name?.toUpperCase().includes('APPLEPLUS') && toValue(campaign)?.promotionalText
	);

	return {
		campaignNode,
		promotionalTitle,
		promotionalText,
		promotionalTextWithGenreFallback,
		hasDisclaimerText,
		releaseYear,
	};
}
